
export default {
  name: 'DeleteOverlay',
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['confirm', 'cancel', 'update'],
  computed: {
    overlayModel: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('update', newVal)
      },
    },
  },
  methods: {
    onConfirm() {
      this.$emit('confirm')
      this.overlayModel = false
    },
    onCancel() {
      this.$emit('cancel')
      this.overlayModel = false
    },
  },
}
